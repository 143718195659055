<script lang="ts" setup>
import Arrow from "@/assets/icons/arrow.svg?component";
import { useSwiper } from "swiper/vue";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    type?: "primary" | "secondary";
    variation?: string;
    swiperController?: ReturnType<typeof useSwiper>;
    items?: Array<{ fallback_color: string }>;
  }>(),
  {
    type: "primary",
  },
);

const swiper = props.swiperController ?? useSwiper();
const fallbackColor = computed(
  () => props.items?.[swiper.value.activeIndex]?.fallback_color,
);
</script>
<i18n lang="json">
{
  "de": {
    "next": "Nächster Slide",
    "previous": "Vorheriger Slide"
  },
  "en": {
    "next": "Next slide",
    "previous": "Previous slide"
  }
}
</i18n>
<template>
  <div class="slider-controls z-10 grid grid-cols-2 gap-2">
    <base-button
      only-icon
      transparent
      :disabled="swiper.activeIndex === 0"
      class="hover:border-integer-white"
      :class="{
        'border-integer-black text-integer-black hover:border-integer-black hover:bg-integer-black hover:text-integer-white':
          fallbackColor === 'white' ||
          fallbackColor === 'gray' ||
          fallbackColor === 'green',
        'border-integer-white text-integer-white hover:border-integer-white hover:bg-integer-white hover:text-integer-black':
          variation === 'Dark',
        'border-integer-white': fallbackColor === 'black',
      }"
      :type="type"
      :aria-label="t('previous')"
      @click="swiper.slidePrev()"
    >
      <Arrow class="rotate-180" />
    </base-button>
    <base-button
      only-icon
      transparent
      :disabled="swiper.isEnd"
      class="hover:border-integer-white"
      :class="{
        'border-integer-black text-integer-black hover:border-integer-black hover:bg-integer-black hover:text-integer-white':
          fallbackColor === 'white' ||
          fallbackColor === 'gray' ||
          fallbackColor === 'green',
        'border-integer-white text-integer-white hover:border-integer-white hover:bg-integer-white hover:text-integer-black':
          variation === 'Dark',
        'border-integer-white': fallbackColor === 'black',
      }"
      :type="type"
      :aria-label="t('next')"
      @click="swiper.slideNext()"
    >
      <Arrow />
    </base-button>
  </div>
</template>
